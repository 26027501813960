import { Link } from '@chakra-ui/next-js';
import Image from 'next/image';

import { routes } from '@endaoment-frontend/routes';
import { ArrowIcon } from '@endaoment-frontend/ui/icons';
import { Button } from '@endaoment-frontend/ui/shared';

import Logo from '../images/logos/logo.svg';

import styles from './NavBar.module.scss';

const SdkIcon = () => (
  <svg width='186' height='186' viewBox='0 0 186 186' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M148.196 81.2641H144.885V68.0181C144.885 53.4475 132.963 41.5261 118.393 41.5261H105.147V38.8769C105.147 26.9554 95.8744 17.6832 83.953 17.6832C72.0316 17.6832 62.7594 26.9554 62.7594 38.8769V42.1884H50.1756C35.605 42.1884 23.6836 54.1098 23.6836 68.6804V88.5494C23.6836 92.5232 26.3328 95.1725 30.3066 95.1725H40.2411C42.228 95.1725 44.2149 95.8348 46.2018 97.8217C48.1887 99.8086 48.851 101.795 48.851 104.445C48.851 108.418 44.8772 111.73 40.2411 111.73H30.3066C26.3328 111.73 23.6836 114.379 23.6836 118.353V138.222C23.6836 152.793 35.605 164.714 50.1756 164.714H70.0447C74.0185 164.714 76.6677 162.065 76.6677 158.091V148.157C76.6677 146.17 77.33 144.183 79.3169 142.196C81.3038 140.209 83.2907 139.547 85.9399 139.547C89.9137 139.547 93.2252 143.52 93.2252 148.157V156.766C93.2252 160.74 95.8744 163.389 99.8482 163.389H119.717C134.288 163.389 146.209 151.468 146.209 136.897V123.651H149.521C161.442 123.651 170.714 114.379 170.714 102.458C169.39 91.1987 160.118 81.2641 148.196 81.2641Z'
      fill='#393F49'
    />
  </svg>
);

const ApiIcon = () => (
  <svg width='186' height='186' viewBox='0 0 186 186' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M24.7725 55.3146H31.6582H155.602H162.487H167.307C167.996 55.3146 168.684 55.3146 169.373 55.3146C169.373 40.0129 157.667 27.7716 143.896 27.7716H43.364C29.5925 27.7716 17.8867 40.0129 17.8867 55.3146C18.5753 55.3146 19.2639 55.3146 19.9524 55.3146H24.7725Z'
      fill='#393F49'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M155.602 69.0861H162.487L169.373 69.0861V134.067C169.373 147.328 158.356 158.601 143.896 158.601H43.364C29.5925 158.601 17.8867 147.328 17.8867 134.067V69.0861H19.9524H24.7725H31.6582H155.602ZM77.7914 133.812C76.4143 133.812 74.3485 133.123 72.9714 132.435L52.3142 113.843C50.937 112.466 50.2484 110.4 50.2484 108.335C50.2484 106.269 50.937 104.892 52.3142 103.515L72.9714 84.9232C75.7257 82.1689 79.8571 82.8575 82.6114 85.6118C85.3657 88.3661 84.6772 92.4975 81.9229 95.2518L66.7742 108.335L81.9229 121.418C84.6772 124.172 85.3657 128.303 82.6114 131.058C81.9229 133.123 79.8571 133.812 77.7914 133.812ZM109.467 133.812C107.401 133.812 105.336 133.124 103.958 131.747C101.204 128.992 101.893 124.861 104.647 122.106L119.796 109.024L104.647 95.9407C101.893 93.1864 101.204 89.0549 103.958 86.3006C106.713 83.5463 110.844 82.8578 113.599 85.6121L134.944 103.515C136.321 104.892 137.01 106.958 137.01 109.024C137.01 111.089 136.321 113.155 134.944 114.532L114.287 132.435C112.91 133.124 110.844 133.812 109.467 133.812Z'
      fill='#393F49'
    />
  </svg>
);

const GraphIcon = () => (
  <svg width='186' height='186' viewBox='0 0 186 186' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M140.828 71.9719C125.617 71.9719 113.285 59.6405 113.285 44.429C113.285 29.2174 125.617 16.886 140.828 16.886C156.04 16.886 168.371 29.2174 168.371 44.429C168.371 59.6405 156.04 71.9719 140.828 71.9719Z'
      fill='#393F49'
    />
    <path
      d='M44.4297 168.372C29.2181 168.372 16.8867 156.04 16.8867 140.829C16.8867 125.617 29.2181 113.286 44.4297 113.286C59.6412 113.286 71.9727 125.617 71.9727 140.829C71.9727 156.04 59.6412 168.372 44.4297 168.372Z'
      fill='#393F49'
    />
    <path
      d='M85.7399 133.944H122.234C127.054 133.944 128.431 129.812 129.12 129.124C129.12 127.746 129.809 123.615 125.677 121.549L45.8026 76.7919C37.5397 71.9719 33.4082 63.0205 36.1625 53.3804C38.9168 43.7404 46.4911 37.5432 56.1312 37.5432H99.5114C103.643 37.5432 106.397 40.2975 106.397 44.429C106.397 48.5604 103.643 51.3147 99.5114 51.3147H56.1312C51.3112 51.3147 49.934 55.4461 49.2454 56.1347C48.5569 56.8233 48.5569 61.6433 52.6883 63.709L132.563 109.155C140.826 113.975 144.957 122.926 142.203 132.566C140.137 141.518 131.874 147.715 122.234 147.715H85.7399C81.6084 147.715 78.8541 144.961 78.8541 140.829C78.8541 136.698 81.6084 133.944 85.7399 133.944Z'
      fill='#393F49'
    />
  </svg>
);

export const NavBar = () => {
  return (
    <header className={styles['header']}>
      <div className={styles['header__inner']}>
        <div className={styles['logo']}>
          <Link href='#'>
            <Image src={Logo} height={32} width={158} alt='Endaoment developers logo' />
          </Link>
        </div>
        <nav className={styles['navigation']}>
          <Link href='#sdk' className={styles['navigation__link']}>
            <SdkIcon />
            SDK
          </Link>
          <Link href='#api' className={styles['navigation__link']}>
            <ApiIcon />
            API
          </Link>
          <Link href='#subgraph' className={styles['navigation__link']}>
            <GraphIcon />
            Graph
          </Link>
          <Button as='a' href={routes.app.home()} className={styles['app-button']} size='small' float={false}>
            Launch App
            <ArrowIcon width={20} height={12} />
          </Button>
        </nav>
      </div>
    </header>
  );
};
