import { Link } from '@chakra-ui/next-js';
import clsx from 'clsx';

import { useIsMobile, useIsMounted } from '@endaoment-frontend/hooks';
import { routes } from '@endaoment-frontend/routes';
import { LogoTextIcon } from '@endaoment-frontend/ui/icons';

import styles from './SuperHeader.module.scss';

const ActiveLink = ({
  href,
  children,
  target,
  className,
  preciseMatch,
}: {
  href: string;
  target?: string;
  children: React.ReactNode;
  className?: string;
  preciseMatch?: boolean;
}) => {
  const origin = typeof window !== 'undefined' && window.location.origin;

  // If `preciseMatch`, check if the href is the same as the current page
  // Otherwise, check if the href starts with the origin
  const isHrefActive =
    origin &&
    (preciseMatch ? href === window.location.href || href === window.location.href + '/' : href.indexOf(origin) === 0);

  const isMounted = useIsMounted();

  return (
    <Link
      href={href}
      target={target}
      className={clsx(styles['super-header__nav__link'], isMounted && isHrefActive && styles['active'], className)}>
      {children}
    </Link>
  );
};

export const SuperHeader = () => {
  const { isMobile } = useIsMobile();
  return (
    <div className={styles['super-header__container']}>
      <div className={styles['super-header']}>
        <div className={styles['super-header__logo']}>
          {/* Not using `routes` as the user might click this to go to the home page of the current site they are on.
              The intuition is to take them to the index for the current site. */}
          <Link href='/'>
            {isMobile ? 'Home' : <LogoTextIcon color='white' width={116} />}
            <span className={styles['desktop-only']}>The Smartest Way to Give</span>
          </Link>
        </div>
        <nav className={styles['super-header__nav']}>
          {/* About -> Marketing Home 
              This is so on the sister sites the user isn't tempted to click "Home" to go to index page. */}
          <ActiveLink href={routes.marketing.home({ useFullUrl: true })} preciseMatch>
            About
          </ActiveLink>

          {/* Company -> Marketing "About" Page
              This is a more accurate description of the page and prevents naming conflict due to using "About" for Marketing home page. */}
          <ActiveLink
            href={routes.marketing.donationCalculator({ useFullUrl: true })}
            className={styles['desktop-only']}
            preciseMatch>
            Calculator
          </ActiveLink>
          <ActiveLink href='https://docs.endaoment.org/' target='_blank'>
            Docs
          </ActiveLink>
          <ActiveLink href={routes.developers.home({ useFullUrl: true })}>Developers</ActiveLink>
          <ActiveLink href={routes.app.home({ useFullUrl: true })}>App</ActiveLink>
        </nav>
      </div>
    </div>
  );
};
